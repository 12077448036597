import clsx from 'clsx'
import {Field, FieldProps} from 'formik'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

interface Props {
    name: string
    label?: string
    type?: string
    value?: string
    placeholder?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    required?: boolean
    allowNegative?: boolean
}
export function TextInputMoneyMask({
    name,
    label,
    type = 'text',
    value,
    placeholder,
    onChange,
    onBlur,
    required = false,
    allowNegative = true,
    ...props
}: Props) {
    const defaultMaskOptions = {
        prefix: 'R$',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '',
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: 2, // how many digits allowed after the decimal
        integerLimit: 8, // limit length of integer numbers
        allowNegative: allowNegative,
        allowLeadingZeroes: false,
    }

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
    })

    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <Field>
                {({form}: FieldProps) => (
                    <>
                        <MaskedInput
                            {...props}
                            mask={currencyMask}
                            id={name}
                            type={type}
                            placeholder={placeholder}
                            value={value || form.values[name]}
                            onChange={onChange || form.handleChange}
                            onBlur={onBlur || form.handleBlur}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': form?.touched[name] && form?.errors[name],
                                },
                                {
                                    'is-valid': form?.touched[name] && form?.errors[name],
                                }
                            )}
                        />
                        {form.touched[name] && form.errors[name] && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                        {form.errors[name]}
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Field>
        </>
    )
}
